import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Link,
  Divider,
} from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';

import {
  GetActivatedGovernanceFrameworksQuery,
  GetProjectGovernanceFrameworksQuery,
} from '../__generated__/gql/graphql';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

const GET_GOVERNANCE_FRAMEWORKS = gql`
  query getActivatedGovernanceFrameworks {
    organization {
      governanceFrameworks {
        id
        description
        name
      }
    }
  }
`;

const GET_PROJECT_GOVERNANCE_FRAMEWORKS = gql`
  query getProjectGovernanceFrameworks {
    allProjects {
      id
      title
      projectGovernanceFrameworks {
        id
        name
        description
      }
    }
  }
`;

export default function GovernanceOverviewPage() {
  const { data } = useSuspenseQuery<GetActivatedGovernanceFrameworksQuery>(
    GET_GOVERNANCE_FRAMEWORKS
  );
  const navigate = useNavigate();

  const { data: projectGovernanceFrameworksData } =
    useSuspenseQuery<GetProjectGovernanceFrameworksQuery>(
      GET_PROJECT_GOVERNANCE_FRAMEWORKS
    );

  const handleSelectOrgLevel = (frameworkId: string) => {
    navigate(`/framework/${frameworkId}/`);
  };

  const handleSelectProjLevel = (projectId: string, frameworkId: string) => {
    navigate(`/project_governance/${projectId}/${frameworkId}`);
  };

  const governanceFrameworks = data?.organization?.governanceFrameworks ?? [];
  const projects = projectGovernanceFrameworksData?.allProjects ?? [];

  return (
    <Box display={'flex'} gap={4} flexDirection={'column'} width={'100%'}>
      <Box>
        <Typography variant="h4" marginBottom={3}>
          Governance Frameworks
        </Typography>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography variant="h5">
              {governanceFrameworks.length === 0 ? (
                <>
                  No Frameworks -{' '}
                  <Link component={RouterLink} to="/organization_settings">
                    Go to the Organization Settings
                  </Link>{' '}
                  to enable a framework
                </>
              ) : governanceFrameworks.length > 1 ? (
                governanceFrameworks.length + ' Frameworks'
              ) : (
                '1 Framework'
              )}
            </Typography>
            <Box display="flex" flexWrap="wrap" gap="12px">
              {governanceFrameworks.map(framework => (
                <Card
                  key={framework!.id}
                  sx={{
                    width: 400,
                    height: 200,
                  }}
                >
                  <CardActionArea
                    sx={{ height: '100%', width: '100%' }}
                    onClick={() => handleSelectOrgLevel(framework!.id)}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      <Typography variant="h6">{framework!.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {framework!.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography variant="h4" marginBottom={3}>
          Project-Level Governance
        </Typography>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {' '}
            {projects.map(project => (
              <Box key={project?.id} marginBottom={4}>
                <Typography variant="h5" marginBottom={2}>
                  {project?.title ?? ''}
                </Typography>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap="12px"
                  sx={{
                    width: '100%',
                    overflowX: 'scroll',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '16px',
                  }}
                >
                  <Box display="flex" flexWrap="wrap" gap="12px">
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '16px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '16px',
                        }}
                      >
                        {project?.projectGovernanceFrameworks?.map(
                          framework => (
                            <Card
                              key={framework?.id}
                              sx={{
                                width: 400,
                                height: 200,
                                flexShrink: 0,
                              }}
                            >
                              <CardActionArea
                                sx={{ height: '100%', width: '100%' }}
                                onClick={() =>
                                  handleSelectProjLevel(
                                    project?.id,
                                    framework?.id ?? ''
                                  )
                                }
                              >
                                <CardContent
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <Typography variant="h6" textAlign="center">
                                    {framework?.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    textAlign="center"
                                  >
                                    {framework?.description}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          )
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
