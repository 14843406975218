import { Card, Grid, Typography } from '@mui/material';
import { axisClasses, BarChart } from '@mui/x-charts';
import { GetRiskAssessmentDashboardQuery } from '../../__generated__/gql/graphql';
import { colorThemes } from '../../theme';

export default function FrequencyStatisticsDashboard({
  data,
}: {
  data: GetRiskAssessmentDashboardQuery['riskAssessmentDashboard'];
}) {
  const frequencyStatistics = data?.frequencyStatistics;
  const categories = ['Rare', 'Unlikely', 'Moderate', 'Likely', 'Often'];
  const counts = [
    frequencyStatistics?.numRare || 0,
    frequencyStatistics?.numUnlikely || 0,
    frequencyStatistics?.numModerate || 0,
    frequencyStatistics?.numLikely || 0,
    frequencyStatistics?.numOften || 0,
  ];

  return (
    <Card
      item
      xs={4}
      sx={{
        borderRadius: 2,
        backgroundColor: colorThemes.DARK_BLUE_600,
        justifyContent: 'center',
        gap: '16px',
        padding: 1,
        height: 300,
        border: '2px solid #334E68',
      }}
    >
      <Typography variant="h6" textAlign="center" gutterBottom>
        Likelihood Distribution
      </Typography>
      <BarChart
        xAxis={[
          {
            scaleType: 'band',
            data: categories,
            colorMap: {
              type: 'ordinal',
              colors: [
                '#00a6fb',
                '#0582ca',
                '#006494',
                '#003554',
                '#03273C',
                '#051923',
              ],
            },
          },
        ]}
        series={[
          {
            data: counts,
          },
        ]}
        sx={{
          borderRadius: '6px 6px 8px 8px',
          marginBottom: '36px',
          bgcolor: '#efeae4',
          color: 'black',
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tick}, .${axisClasses.line}`]: {
              stroke: 'black',
              strokeWidth: 1,
            },
            [`.${axisClasses.tickLabel}`]: {
              fill: 'black',
            },
          },
        }}
      />
    </Card>
  );
}
