import { Grid } from '@mui/material';
import AverageSeverityCircularDashboard from './AverageSeverityCircularDashboard';
import { gql, useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GetRiskAssessmentDashboardQuery } from '../../__generated__/gql/graphql';
import FrequencyStatisticsDashboard from './FrequencyStatisticsDashboard';
import ImpactStatisticsDashboard from './ImpactStatisticsDashboard';
import NumberDisplay from './NumberDisplay';
import RiskClassDashboard from './RiskClassDashboard';

const GET_RISK_ASSESSMENT_DASHBOARD = gql(`
     query getRiskAssessmentDashboard($projectId: String!) {
        riskAssessmentDashboard(projectId: $projectId) {
            averageSeverity(projectId: $projectId)
            riskClassStatistics(projectId: $projectId) {
                numModerateRisk
                numSeriousRisk
                numMajorRisk
                numCriticalRisk
            }
            severityStatistics(projectId: $projectId) {
                numSerious
                numModerate
                numMinor
            }
            frequencyStatistics(projectId: $projectId) {
                numRare
                numUnlikely
                numModerate
                numLikely
                numOften
            }
            impactStatistics(projectId: $projectId) {
                numSystemHigh
                numSystemModerate
                numSystemMinor
                numUserHigh
                numUserModerate
                numUserMinor
                numBusinessHigh
                numBusinessModerate
                numBusinessMinor
            }
            numAssessedRisks(projectId: $projectId)
            numUntreatedHighRisk(projectId: $projectId)
  }
}
`);

export default function RiskManagementDashboard() {
  const { projectId } = useParams();
  const { data } = useSuspenseQuery<GetRiskAssessmentDashboardQuery>(
    GET_RISK_ASSESSMENT_DASHBOARD,
    {
      variables: { projectId },
    }
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <RiskClassDashboard data={data.riskAssessmentDashboard} />
      </Grid>
      <Grid item xs={6}>
        <FrequencyStatisticsDashboard data={data.riskAssessmentDashboard} />
      </Grid>
      <Grid item xs={6}>
        <ImpactStatisticsDashboard data={data.riskAssessmentDashboard} />
      </Grid>
      <Grid item xs={2}>
        <AverageSeverityCircularDashboard
          value={data.riskAssessmentDashboard?.averageSeverity || 0}
        />
      </Grid>
      <Grid item xs={2}>
        <NumberDisplay
          title="Assessed Risks"
          value={data.riskAssessmentDashboard?.numAssessedRisks || 0}
        />
      </Grid>

      <Grid item xs={2}>
        {' '}
        <NumberDisplay
          title="Untreated High Risks"
          value={data.riskAssessmentDashboard?.numUntreatedHighRisk || 0}
        />
      </Grid>
    </Grid>
  );
}
