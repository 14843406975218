import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';

import AddNewItemCard from '../components/AddNewItemCard';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetTuvRiskAssessmentsQuery,
  RiskAssessmentFrameworkFieldsFragment,
} from '../__generated__/gql/graphql';
import NewRiskAssessmentModal from './NewRiskAssessmentModal';
import { useState } from 'react';
import { colorThemes } from '../theme';
import RiskManagementDashboard from './dashboards/RiskManagementDashboard';

const GET_TUV_RISK_ASSESSMENTS = gql(`
  query getTuvRiskAssessments($projectId: String!) {
    project(id: $projectId) {
      riskAssessmentFrameworks {
        ...RiskAssessmentFrameworkFields
      }
    }
  }
  fragment RiskAssessmentFrameworkFields on RiskAssessmentFrameworkMaterialized {
    id
    name
  }
`);

export default function RiskManagement() {
  const { projectId } = useParams();
  const { data } = useSuspenseQuery<GetTuvRiskAssessmentsQuery>(
    GET_TUV_RISK_ASSESSMENTS,
    {
      variables: { projectId },
    }
  );

  const riskAssessmentFrameworks =
    data?.project?.riskAssessmentFrameworks ?? [];
  const [showRiskAssesmentModal, setShowRiskAssesmentModal] = useState(false);

  return (
    <>
      <Box display={'flex'} gap={2} flexDirection={'column'} width={'100%'}>
        <Typography variant="h4" marginBottom={3}>
          Risk Assessments
        </Typography>
        <RiskManagementDashboard />
        <Box width={'100%'} display={'flex'}>
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <Typography variant="h6">
              {riskAssessmentFrameworks.length === 0
                ? 'No Frameworks'
                : riskAssessmentFrameworks.length > 1
                ? riskAssessmentFrameworks.length + ' Frameworks'
                : '1 Framework'}
            </Typography>
            <Box display="flex" flexWrap="wrap" gap="12px">
              {riskAssessmentFrameworks.map(assessment => (
                <AssesmentCard
                  assessment={
                    assessment as RiskAssessmentFrameworkFieldsFragment
                  }
                  projectId={projectId}
                />
              ))}

              <AddNewItemCard
                onClick={() => setShowRiskAssesmentModal(true)}
                sx={{ width: 400, height: 200 }}
              />
            </Box>
            <NewRiskAssessmentModal
              open={showRiskAssesmentModal}
              onClose={() => setShowRiskAssesmentModal(false)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

function AssesmentCard({
  assessment,
  projectId,
}: {
  assessment: RiskAssessmentFrameworkFieldsFragment;
  projectId?: string;
}) {
  const history = useNavigate();

  const handleSelect = (name: string, frameworkId: string) => {
    history(`/risk_assessment/${projectId}/${name}/${frameworkId}/`);
  };
  return (
    <Card
      key={assessment!.id}
      sx={{
        width: 400,
        height: 200,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_400,
        },
      }}
    >
      <CardActionArea
        sx={{ height: '100%', width: '100%' }}
        onClick={() => handleSelect(assessment!.name, assessment!.id)}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography variant="h6">
            {assessment!.name} Risk Assessment
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
